import React, { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import SchoolSidebar from "../components/schools/school sidebar/schoolSidebar";
import StudentSidebar from "../components/students/student sidebar/studentSidebar";

export const StudentContext = createContext(null);

function StudentDashboardWrapper() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [studentActiveTab, setStudentActiveTab] = useState("Dashboard");
  const [open, setOpen] = useState(true);
  const [currentAddress, setCurrentAddress] = useState("");

  return (
    <div className="grid lg:grid-cols-6 h-screen">
      <StudentContext.Provider
        value={{
          sidebarOpen,
          setSidebarOpen,
          studentActiveTab,
          setStudentActiveTab,
          setOpen,
          open,
          currentAddress,
          setCurrentAddress,
        }}>
        <div>
          <StudentSidebar />
        </div>
        <div className="lg:col-span-5 bg-white">
          <Outlet />
        </div>
      </StudentContext.Provider>
    </div>
  );
}

export default StudentDashboardWrapper;
